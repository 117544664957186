import React, { useState, useEffect } from "react";
import axios from "axios";
import pako from "pako";

import Inbetween from "./Inbetween";

import { CookieBanner } from "@palmabit/react-cookie-law";
//import { Helmet } from "react-helmet";

import ReactGA from "react-ga4";
//import { initGA } from "./ga-utils";

//ReactGA.initialize("G-YC142WEB05");
//ReactGA.send("pageview"); // can change message to whatever I like (I think)

//import logo_svg from "./logo.svg";

//const baseURL = "https://papersrepo.herokuapp.com/api/"; // https://papersrepo.herokuapp.com/api/ , http://localhost:5000/api
//const papersURL = "https://papersrepo.herokuapp.com/paper/";
//const testURL = "https://papersrepo.herokuapp.com/test/";

const herokuOrLocal = true;
var baseURL = "";
var papersURL = "";
//var allPapersURL = "";

if (herokuOrLocal === true) {
  baseURL = "https://examoo.physicsfox.org/api/"; // https://papersrepo.herokuapp.com/api/ , http://localhost:5000/api
  papersURL = "https://examoo.physicsfox.org/paper/";
  //allPapersURL = "https://papersrepo.herokuapp.com/allpapers/";
} else {
  baseURL = "http://localhost:5000/api/"; // https://papersrepo.herokuapp.com/api/ , http://localhost:5000/api
  papersURL = "http://localhost:5000/paper/";
  //allPapersURL = "http://localhost:5000/allpapers/";
}

function Main() {
  /*
	const location = window.location.pathname; // gets current url
	console.log('loc: ' + location);
	var loc_path = location; // '/gcse/maths/aqa'
	var loc_parts = loc_path.split('/'); // ['', 'gcse', 'maths', 'aqa']
	*/

  const [pathNavBar, setPathNavBar] = useState("/"); // for remembering url of exam page (when going away and back again.)

  // ---=== [1] ===---
  // ------ GET ALL DJANGO SERVER DATA (excluding papers from S3) ------

  const [qualData, setQualData] = useState(null);
  const [colourData, setColourData] = useState(null);
  const [subjData, setSubjData] = useState(null);
  const [examBoardData, setExamBoardData] = useState(null);
  const [paperTypeData, setPaperTypeData] = useState(null);

  const [testData, setTestData] = useState(null);
  const [paperData, setPaperData] = useState(null);
  //const [allPaperData, setAllPaperData] = useState(null);

  /*
	useEffect(() => {
		document.title = "BAD TITLE"
	}, []);
	*/

  useEffect(() => {
    // useEffect is for updating "side effects" on every render. (a side effect is a function that relies on variables outside of it own vars)
    // Common side effects include updating browser page title and getting data.
    // By default, will run everytime the page is re-rendered.
    // Add ", [count]" at end of useEffect to only update when 'count' updates.
    // Therefore ", []" never updates after initial render.

    // Some effects have additional code for 'cleanup phase' - something to do with the effect returning a function at the end

    axios
      .get(baseURL + "qualifications")
      .then(function (response) {
        setQualData(response.data);
        //console.log('qualdata INSIDE:')
        //console.log(qualData); // returns null *here*, but NOT after useEffect!
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(baseURL + "colours")
      .then(function (response) {
        setColourData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(baseURL + "subjects")
      .then(function (response) {
        setSubjData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(baseURL + "examboards")
      .then(function (response) {
        setExamBoardData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(baseURL + "papertypes")
      .then(function (response) {
        setPaperTypeData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    // Below is just for testing. Not sure why its necessary
    // !! probably because of '|| !paperData' below. Can just delete it?

    /* NEWDATA 6/4/22

		axios.get(papersURL+"uk/gcse/maths/aqa/")
			.then(function (response) {
				setPaperData(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
		*/

    /*
		const {dataaa} = await axios.get("https://examoo.s3.eu-west-2.amazonaws.com/eg4comp.gz", { responseType: 'arraybuffer', 'decompress': true })
						.then(function (response) {
							setPaperData(response.data);
						})
						.catch(function (error) {
							// handle error
							console.log(error);
						});
		*/

    /*
		const fetchhh = async () => {
			const result = await axios.get("https://examoo.s3.eu-west-2.amazonaws.com/eg4comp.gz", { responseType: 'arraybuffer', 'decompress': true })
		
			setPaperData(result.data);
			console.log('got the papers.');
		};

		fetchhh();
		*/

    const urls = [
      "https://examoo.s3.eu-west-2.amazonaws.com/bigpaperfile.gz", // big1comp.gz // txttestcomp.gz
    ];

    async function exec(i = 0) {
      //console.group('json file: ', i); // creates a 'group' of info in the console
      try {
        // fetch file with CORS enabled
        const res = await fetch(urls[i], {
          mode: "cors",
        });
        // convert to arrayBuffer for further processing
        const buf = await res.arrayBuffer();
        // or get blob using `await res.blob()`
        // and convert blob to arrayBuffer using `await blob.arrayBuffer()`

        //console.log('input size: ', buf.byteLength, 'bytes');

        // decompress file
        const outBuf = pako.inflate(buf);
        //console.log('output size: ', outBuf.byteLength, 'bytes');

        // convert arrayBuffer to string
        const str = new TextDecoder().decode(outBuf);
        //console.log('json string', str);

        // print json object
        const jsonobj = JSON.parse(str);
        //console.log('json object', jsonobj);

        //const thing = {};
        //thing['papers'] = JSON.parse(str);
        //setPaperData(thing);

        setPaperData(jsonobj);

        //console.log('SUCCESS: gz file decompressed');

        //setPaperData(str);

        //return str;
      } catch (err) {
        console.error("unable to decompress gz file", err);
      }
      //console.groupEnd('json file: ', i); // ends the group of info in the console
    }

    async function doit() {
      for (let i in urls) {
        await exec(i);
      }
    }

    doit();

    // just .JSON:
    /*
		fetch('https://examoo.s3.eu-west-2.amazonaws.com/txttest22may3.json').then(response => {
				console.log(response);
				return response.json();
			}).then(data => {
				console.log(data);
			}).catch(err => {
				// Do something for an error here
				console.log("Error Reading data " + err);
			});
		*/

    /*
		let isMounted = true;  
		// note mutable flag
		doit().then(data => {
			if (isMounted) setPaperData(data);    // add conditional check
		})
		return () => { isMounted = false }; 
		*/

    /*
		doit().then(data => {
			setPaperData(data);    // add conditional check
		})
		*/

    //console.log(paperData);

    /*
		fetch('../../eg4.json').then(response => { //2x100 for big
				console.log(response);
				return response.json();
			}).then(data => {
				setPaperData(data);
				console.log(data);
			}).catch(err => {
				// Do something for an error here
				console.log("Error Reading data " + err);
			});
		*/

    /*
		fetch('../../eg2x100.json').then(response => { //2x100 for big
				console.log(response);
				return response.json();
			}).then(data => {
				console.log(data);
			}).catch(err => {
				// Do something for an error here
				console.log("Error Reading data " + err);
			});
		*/

    /*
		axios
		.get('https://examoo.s3.eu-west-2.amazonaws.com/txttest22may3.json')
		.then(function (response) {
			setPaperData(response.data);
		})
		.catch(function (error) {
			// handle error
			console.log(error);
		});
		*/

    /*

		axios.get(allPapersURL+"uk/")
		.then(function (response) {
			setAllPaperData(response.data);
		})
		.catch(function (error) {
			// handle error
			console.log(error);
			});

			
		console.log('getting papers (USEEFFECT)...');
		//console.log(props.paperData);

		const fetchData = async () => {
			const result = await axios(
				papersURL+"uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+loc_parts[3]+"/",
			);
		
			props.setPaperData(result.data);
			console.log('got the papers.');
		};
	
		fetchData();

		*/
  }, []);

  /*
	const handleAcceptCookie = () => {
		//if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
		//  initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
		//}

		ReactGA.initialize("G-YC142WEB05");
		ReactGA.send("pageview"); // can change message to whatever I like (I think)
		
	};
	
	const handleDeclineCookie = () => {
		//remove google analytics cookies
		Cookies.remove("_ga");
		Cookies.remove("_gat");
		Cookies.remove("_gid");
	};

	useEffect(() => {
		const isConsent = getCookieConsentValue();
		if (isConsent === "true") {
			handleAcceptCookie();
		}
	}, []);
	*/

  /*
	console.log("QUAL DATA:")
	console.log(qualData) // works fine
	*/

  // returns null to the entire page! (instead of usual return of JSX code below)
  // when something is returned, rest of code isn't ran.
  if (!qualData || !subjData || !examBoardData || !paperTypeData || !paperData)
    return (
      <>
        {/*
			<Helmet>
				<meta charSet="utf-8" />
				<title>NooOOoOoO</title>
				<!-- <link rel="canonical" href="http://mysite.com/example" /> -->
			</Helmet>
			*/}

        <div className="logo-parent">
          <a href="/">
            {" "}
            {/* Used to be <NavLink>, but need <a> to have total page refresh (so that qual, subj aren't remembered.) */}
            <div className="logo" id="logo-id">
              {/* <img className="spinning" style={{width: '85px', margin: '20px 0 0 0'}} src={logo_svg} alt="Examoo Logo"/> */}
              <svg
                className="spinning"
                style={{ width: "85px", margin: "20px 0 0 0" }}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 1.9 82.5 63.6"
                xmlSpace="preserve"
              >
                <path
                  fill="#404041"
                  d="M75.3,21l0.3,33.3c0,6.2-5.7,11.2-12.6,11.2H27.5c-7,0-12.6-5-12.6-11.2V23.4c0-6.2,5.7-11.2,12.6-11.2h35.5
							c2.6,0,12.4-1.4,12.4,7.7"
                />
                <path
                  fill="#FFAEEC"
                  d="M59.8,62.9H24.3c-7,0-13.8-5.3-13.8-11.4V20.5c0-6.2,6.9-10.9,13.8-10.9h35.5c2.6,0,22.7-0.7,22.7,7.1
							c-0.4,6.8-5.2,7.3-8.6,5c0,0-2-1.4-1.8,3.6l0.3,26.4C72.4,57.9,66.8,62.9,59.8,62.9z"
                />
                <path
                  fill="#FFAEEC"
                  d="M70.9,30.9v-8.6c0-7-5.7-12.6-12.6-12.6H22.8c-2.6,0-22.7-0.8-22.7,8c0.5,6.6,6.9,6,10.3,3.4
							c0,0,0.3,0.7,0.1,6.3l0,4.2L70.9,30.9z"
                />
                <path
                  fill="#FFFFFF"
                  d="M56.6,60H21.1c-7,0-12.6-5-12.6-11.2V18c0-6.2,5.7-11.2,12.6-11.2h35.5c2.6,0,28.9-0.1,25.6,11.9
							c1.6-5.9-13.2-6.8-13.2,3.8l0.3,26.4C69.2,55,63.6,60,56.6,60z"
                />
                <path
                  fill="none"
                  stroke="#404041"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  d="M49.4,29.9
							c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1"
                />
                <path
                  fill="none"
                  stroke="#404041"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  d="M17.3,29.7
							c0-3.4,2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1"
                />
                <path
                  fill="#58595B"
                  d="M42.3,60l14,0.1c7.3,0,12.9-4.5,12.9-11.4c0-6.3-0.1-8.1-0.1-8.1C61.6,40.6,42.3,52.2,42.3,60z"
                />
                <path
                  fill="#404041"
                  d="M21.6,6.7c-10,0-22.6,4-21.5,11.5c-0.3-2.7,8.4-6.7,8.4,0.2l0,5.6c3.5,3.1,3.8-4.4,11.8-6.1
							c8.1-1.6,9,3.3,18.8,3.3C52.1,21.2,51,8.7,45,6.7"
                />
                <path
                  fill="#FFAEEC"
                  d="M29.5,54.6h19.1c5.1,0,9.3-4.2,9.3-9.3v0c0-5.1-4.2-9.3-9.3-9.3H29.5c-5.1,0-9.3,4.2-9.3,9.3v0
							C20.2,50.4,24.4,54.6,29.5,54.6z"
                />
                <circle fill="#404041" cx="29.7" cy="41.6" r="2.3" />
                <circle fill="#404041" cx="49.4" cy="41.7" r="2.3" />
                <path
                  fill="#404041"
                  d="M75.4,22.5c-0.2,0-2.1-1.8-2.1-1.8s-1.1,0.4-1.1,2.3c0,1.9,0.1,5.5,0.1,5.5c0,0.8,0.7,1.4,1.5,1.4H74
							c0.8,0,1.4-0.6,1.4-1.4c0,0,0-0.6,0-3.4C75.3,22.2,75.4,22.5,75.4,22.5z"
                />
                <path
                  fill="#404041"
                  d="M58.9,7.2c-0.1,1.5,1.7,2.9,4.1,3c2.3,0.1,4.3-1,4.4-2.6S65.7,2,63.4,1.9C61.1,1.8,59,5.7,58.9,7.2z"
                />
                <path
                  fill="#404041"
                  d="M11.1,8.2c0.2,1.5,2.2,2.6,4.5,2.4c2.3-0.2,4.1-1.7,3.9-3.2c-0.2-1.5-2.5-5.6-4.8-5.4S10.9,6.6,11.1,8.2z"
                />
              </svg>
              <h1
                className="title-loading"
                style={{
                  margin: "-10px 5px 5px 5px",
                  fontWeight: "600",
                  fontSize: "25px",
                  color: "black",
                  position: "relative",
                  zIndex: 1000,
                }}
              >
                examoo
              </h1>
            </div>
          </a>
        </div>
        {/*  <div className="loading">Loading...</div> */}

        {/*
			<CookieConsent style={{zIndex: '9999'}}
				enableDeclineButton
				onAccept={handleAcceptCookie}
				onDecline={handleDeclineCookie}
			>
				This website uses cookies to enhance the user experience.
			</CookieConsent>
			*/}

        <CookieBanner
          message="This website uses cookies in order to work as intended 🍪"
          styles={{
            dialog: {
              display: "block",
              position: "fixed",
              bottom: "15px",
              background: "#000000c0",
              opacity: "1",
              width: "80%",
              borderRadius: "30px",
              padding: "20px",
              paddingTop: "15px",
              left: "0",
              right: "0",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: "10001",
              webkitBackdropFilter: "blur(10px) !important",
              backdropFilter: "blur(10px) !important",
              filter: "drop-shadow(0px -5px 13px #00000035)",
            },

            message: {
              color: "white",
              fontSize: "28px",
              lineHeight: "30px",
              fontWeight: "bold",
              margin: "10px",
              marginBottom: "20px",
            },

            policy: {
              color: "#bbb",
              fontSize: "16px",
              /* width: 300px; */
              display: "flex",
              justifyContent: "center",
            },

            buttonWrapper: {
              display: "flex",
              justifyContent: "center",
            },

            selectPane: {
              padding: "3px 0px 35px",
              textAlign: "center !important",
              margin: "0px auto !important",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          }}
          wholeDomain={true}
          managePreferencesButtonText={"Manage cookies"}
          policyLink={"/cookies"}
          privacyPolicyLinkText={"Cookie Policy"}
          onAccept={() => {}} // necessary cookies here
          onAcceptPreferences={() => {}}
          onAcceptStatistics={() => {
            ReactGA.initialize("G-YC142WEB05");
          }}
          onAcceptMarketing={() => {}}
        />

        <p
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "100px",
            color: "#bfbfbf",
            fontSize: "24px",
            lineHeight: "29px",
          }}
        >
          Loading <br />
          past papers...
        </p>
      </>
    );

  return (
    <>
      {/*
		<CookieConsent style={{zIndex: '9999'}}
			enableDeclineButton
			onAccept={handleAcceptCookie}
			onDecline={handleDeclineCookie}
      	>
			This website uses cookies to enhance the user experience.
		</CookieConsent>
		*/}

      <CookieBanner
        message="This website uses cookies in order to work as intended 🍪"
        styles={{
          dialog: {
            display: "block",
            position: "fixed",
            bottom: "15px",
            background: "#000000c0",
            opacity: "1",
            width: "80%",
            borderRadius: "30px",
            padding: "20px",
            paddingTop: "15px",
            left: "0",
            right: "0",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: "10001",
            webkitBackdropFilter: "blur(10px) !important",
            backdropFilter: "blur(10px) !important",
            filter: "drop-shadow(0px -5px 13px #00000035)",
          },

          message: {
            color: "white",
            fontSize: "28px",
            lineHeight: "30px",
            fontWeight: "bold",
            margin: "10px",
            marginBottom: "20px",
          },

          policy: {
            color: "#bbb",
            fontSize: "16px",
            /* width: 300px; */
            display: "flex",
            justifyContent: "center",
          },

          buttonWrapper: {
            display: "flex",
            justifyContent: "center",
          },

          selectPane: {
            padding: "3px 0px 35px",
            textAlign: "center !important",
            margin: "0px auto !important",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          },
        }}
        wholeDomain={true}
        managePreferencesButtonText={"Manage cookies"}
        policyLink={"/cookies"}
        privacyPolicyLinkText={"Cookie Policy"}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {
          ReactGA.initialize("G-YC142WEB05");
        }}
        onAcceptMarketing={() => {}}
      />

      {/*
		<Helmet>
			<meta charSet="utf-8" />
			<title>yeAH EYAEHA </title>
		</Helmet>
		*/}

      <Inbetween
        pathNavBar={pathNavBar}
        setPathNavBar={setPathNavBar}
        qualData={qualData}
        setQualData={setQualData} // probs don't need?
        colourData={colourData}
        setColourData={setColourData} // probs don't need?
        subjData={subjData}
        setSubjData={setSubjData} // etc.
        examBoardData={examBoardData}
        setExamBoardData={setExamBoardData}
        paperTypeData={paperTypeData}
        setPaperTypeData={setPaperTypeData}
        testData={testData}
        setTestData={setTestData}
        paperData={paperData}
        //setPaperData={setPaperData}
      />
    </>
  );
}

export default Main;
